import React from 'react';

const NotFound = () => {
    return (
        <div style={{textAlign: 'center', padding: '40px'}}>
            <h1>404</h1>
            <br/>
            <h3>Oops</h3>
            <h4>Page not found ...</h4>
        </div>
    );
}

export default NotFound;
