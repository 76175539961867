const { Link } = require("react-router-dom")
const { countFormatterWithCommas, multipurposeSortComparator, getPercentageSkippedOverTotal } = require("./helpers")
const dayjs = require("dayjs")

const SAMPLE_DATASET_CLUSTERS = [
  {
    "clusterName": "Inspira",
    "id": 1,
    "overallPending": 0,
    "overallScanned": 0,
    "overallSkipped": 0,
    "scanSpeedDays1": -11355084,
    "scanSpeedDays5": 0.0
  },
  {
    "clusterName": "Snapfinance",
    "id": 2,
    "notReadyDatasources": null,
    "overallPending": 199290128,
    "overallScanned": 16577025,
    "overallSkipped": 8045534,
    "scanSpeedDays1": 21515,
    "scanSpeedDays5": 441768.2,
    "timestamp": "2024-08-22T00:01:59.293051089Z",
    "totalDatasourceConnected": 19
  },
  {
    "clusterName": "PlanetCourier",
    "id": 3,
    "notReadyDatasources": null,
    "overallPending": 35,
    "overallScanned": 278233,
    "overallSkipped": 206335,
    "scanSpeedDays1": 608,
    "scanSpeedDays5": 404.0,
    "timestamp": "2024-08-22T00:01:08.985212656Z",
    "totalDatasourceConnected": 9
}]

const HomePageColumns = [
  {
    field: 'id',
    headerName: 'Id',
    width: 50
  },
  {
    field: 'clusterName',
    headerName: 'Cluster name',
    width: 150,
    renderCell: (cellValue) => {
      return <Link to={`/dashboard/${cellValue.value}/datasources/overview`}>{cellValue.value}</Link>
    }
  },
  {
    field: 'totalDatasourceConnected',
    headerName: 'Connected Datasources',
    width: 150
  },
  {
    field: 'notReadyDatasources',
    headerName: 'Unready Datasources',
    width: 150
  },
  {
    field: 'overallPending',
    headerName: 'Overall Pending',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'overallScanned',
    headerName: 'Overall Scanned',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'overallSkipped',
    headerName: 'Overall Skipped',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'percentageSkipped',
    headerName: 'Skipped:Scanned ratio',
    width: 150,
    valueGetter: getPercentageSkippedOverTotal,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'processingSpeedDays1',
    headerName: 'Processed count 1 day average',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'processingSpeedDays5',
    headerName: 'Processed count 5 day average',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'pendingChangeDays1',
    headerName: 'Pending count daily change',
    width: 150,
    valueFormatter: countFormatterWithCommas,
    getSortComparator: multipurposeSortComparator
  },
  {
    field: 'timestamp',
    headerName: 'Last update time',
    with: 200,
    valueFormatter: (date) => {
      if (!date) {
        return "Unavailable"
      }
      return dayjs(date).format('MM/DD/YYYY HH:mm:ss[Z]')
    }
  }
]

export {
  HomePageColumns,
  SAMPLE_DATASET_CLUSTERS
}