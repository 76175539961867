import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';

const NumberDisplayCard = ({color, count, infoText, variant='h2'}) => {
    return (
        <Card sx={{ minWidth: 200, margin: '20px', maxWidth: 350, textWrap: 'wrap' }} elevation={4}>
            <CardContent>
                <Typography variant={variant} color={color} align='center'>{count}</Typography>
                <Typography align='center'>{infoText}</Typography>
            </CardContent>
        </Card>
    );
}

export default NumberDisplayCard;
