import { configureStore } from '@reduxjs/toolkit'
import homeReducer from './pages/home/homeSlice'
import datasourceReducer from './pages/datasources/datasourceSlice'
import dashboardReducer from './pages/dashboard/dashboardSlice'

export default configureStore({
  reducer: {
    home: homeReducer,
    datasources: datasourceReducer,
    dashboard: dashboardReducer
  },
})