import { Switch } from '@mui/material';
import React from 'react';

const ClusterSwitch = ({cellValue, handleClusterStateChange}) => {
    const handleChange = (event) => {
        handleClusterStateChange({
            'clusterName': cellValue.row.clusterName,
            'isInternal': event.target.checked
        })
    }
        return (
            <Switch
                checked={cellValue.value}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        )

}

export default ClusterSwitch;
