import { Google } from '@mui/icons-material';
import { Box, Button, Checkbox, Container, Divider, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import logo from '../../resources/logoblue.png'
import { Link, useNavigate } from 'react-router-dom';
import { API } from '../../api/api_setup';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useAuth } from '../../hooks/useAuth';

const Home = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { showSnackbar } = useSnackbar();
  const { login } = useAuth()
  const navigate = useNavigate()

  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleRegister = () => {
    navigate("/signup")
  }

  const handleLogin = () => {
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}/
    if (!emailRegex.test(email)) {
      showSnackbar("Please enter a valid email")
      return;
    }
    if (!password || password.length < 8) {
      showSnackbar("Please enter a valid password")
      return;
    }

    API.post("/user/login", {
      email,
      password
    })
      .then((resp) => {
        login({
          token: resp.data.data.token,
          email: email
        })
      })
      .catch((err) => {
        console.log(err)
        if (err.data && err.data.message)
          showSnackbar(err.data.message)
        else if (err.response && err.response.data && err.response.data.message)
          showSnackbar(err.response.data.message)
        else
          showSnackbar("Something went wrong !!")
      })

  }

  return (
    <Container component="section" sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} sx={{ flexGrow: 1, alignItems: 'center' }}>
        <Grid item xs={12} md={6}>
          <img
            src={logo}
            alt="Sample"
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} md={6} container alignItems="center" justifyContent="baseline">
          <Box component="form" sx={{ width: '100%', maxWidth: 400, marginLeft: '60px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Typography variant="h6" gutterBottom>
                Sign in with
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', ml: 2 }}>
                <Button variant="contained" color="primary" sx={{ m: 0.5 }}>
                  <Google />
                </Button>
              </Box>
            </Box>
            <Divider sx={{ my: 4 }}>
              <Typography variant="body2" color="text.secondary">
                Or
              </Typography>
            </Divider>
            <TextField
              fullWidth
              type='email'
              label="Email address"
              variant="outlined"
              margin="normal"
              placeholder="name@lightbeam.ai"
              value={email}
              onChange={onEmailChange}
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              variant="outlined"
              margin="normal"
              placeholder="Enter password"
              value={password}
              onChange={onPasswordChange}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
              <FormControlLabel
                control={<Checkbox />}
                label="Remember me"
              />
              <Link to={"/forgot-password"} style={{ textDecoration: 'none' }}>
                <Typography variant="body2" color="primary">
                  Forgot password?
                </Typography>
              </Link>
            </Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth sx={{ mb: 2 }}
              onClick={handleLogin}
            >
              Login
            </Button>
            <Typography variant="body2" align="center">
              Don't have an account?
              <Button
                variant="text"
                color="secondary"
                onClick={handleRegister}
              >
                Register
              </Button>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;
