import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSnackbar } from '../../hooks/useSnackbar';
import { API } from '../../api/api_setup';
import { useNavigate, useParams } from 'react-router-dom';

const ResetPassword = () => {
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const navigate = useNavigate()
    const {token} = useParams()
    const {showSnackbar} = useSnackbar()
    const onPasswordChange = (e) => {
        setPassword(e.target.value)
    }
    const onPasswordChange2 = (e) => {
        setPassword2(e.target.value)
    }

    const handleReset = () => {
        if (!password || password.length < 8) {
            showSnackbar("Please enter a stronger password !!")
            return
        }

        API.post("/user/reset_password", {
            token,
            password
        })
        .then(resp => {
            showSnackbar("Password reset successfully, redirecting to homepage !!")
            setTimeout(()=>{
                navigate("/" , {replace: true})
            }, 1000)
        })
        .catch(err => {
            showSnackbar("This is not a valid reset request,  redirecting to homepage !!")
            setTimeout(()=>{
                navigate("/", {replace: true})
            }, 1000)
        })
    }

    return (
        <Grid container spacing={2} sx={{ flexGrow: 1, alignItems: 'center' }}>
        <Grid item lg={12} container alignItems="center" justifyContent="space-around">
            <Box component="form" sx={{ width: '100%', maxWidth: 400, marginTop: '50px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                       Reset your account password
                    </Typography>
                </Box>
                <Divider sx={{ my: 4 }}>
                </Divider>
                <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    placeholder="password"
                    value={password}
                    onChange={onPasswordChange}
                />
                <TextField
                    fullWidth
                    label="Enter password again"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    placeholder="password"
                    value={password2}
                    onChange={onPasswordChange2}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth sx={{ mb: 2 , mt: 4}}
                    onClick={handleReset}
                >
                    Reset Password
                </Button>
            </Box>
        </Grid>
    </Grid>
    );
}

export default ResetPassword;
