import { Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import MenuIcon from './MenuIcon';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import ChecklistIcon from '@mui/icons-material/Checklist';
import LogoutIcon from '@mui/icons-material/Logout';

const DrawerMenu = () => {
    const [open, setOpen] = useState(false);
    const { logout } = useAuth()
    const navigate = useNavigate()
    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };
    const menuItems = [
        {
          name: 'View/Modify Clusters',
          clickHandler: () => {
            navigate("/clusters")
          },
          icon : ChecklistIcon
        },
        {
          name: 'Logout',
          clickHandler: () => {
            logout()
            navigate("/")
          },
          icon: LogoutIcon
        }
      ]
      const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            {menuItems.map((item, index) => (
              <ListItem key={item.name} disablePadding>
                <ListItemButton onClick={item.clickHandler}>
                    {
                        item.icon ? 
                        <ListItemIcon>
                        {<item.icon/>}
                      </ListItemIcon> : null
                    }
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      );
    return (
        <Box>
        <IconButton
          size="large"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 0 }}
          id="basic-button"
          variant='contained' 
          onClick={toggleDrawer(true)}>
            <MenuIcon />
        </IconButton>
        <Drawer
          anchor='right'
          open={open}
          onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>

      </Box>
    );
}

export default DrawerMenu;
