import { IconButton, Snackbar } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import React from 'react';

const CustomSnackbar = ({handleCloseSnackbar, openSnackbar, message="Something went wrong!!"}) => {
    const action = (
        <React.Fragment>
    
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <GridCloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
    return (
        <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={message}
        action={action}
    />
    );
}

export default CustomSnackbar;
