import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

const defaultSortModelChange = (model, details) => {

}

export const StickyDataGrid = styled(DataGrid)(({theme}) => ({
  '& .MuiDataGrid-columnHeaders': {
      position: "sticky",
      // Replace background colour if necessary
      backgroundColor: theme.palette.background.paper,
      // Display header above grid data, but below any popups
      zIndex: theme.zIndex.mobileStepper - 1,
  },
  '& .MuiDataGrid-virtualScroller': {
      // Undo the margins that were added to push the rows below the previously fixed header
      marginTop: "0 !important"
  },
  '& .MuiDataGrid-main': {
      // Not sure why it is hidden by default, but it prevented the header from sticking
      overflow: "visible"
  }
}))

const DataDisplay = ({ 
  rows, 
  columns, 
  loading, 
  handleCellClick, 
  sortModel = [], 
  onSortModelChange = defaultSortModelChange, 
  sx={},
  pageSize=100,
  pageSizeOptions = [100,200],
  rowHeight = 52
}) => {
  return (
    <DataGrid
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      onCellClick={handleCellClick}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSize,
          },
        },
      }}
      showCellVerticalBorder={true}
      pageSizeOptions={pageSizeOptions}
      disableRowSelectionOnClick
      loading={loading}
      slotProps={{
        loadingOverlay: {
          variant: 'linear-progress',
          noRowsVariant: 'linear-progress',
        },
      }}
      sx={sx}
      rowHeight={rowHeight}
    />
  );
}

export default DataDisplay;