import { Box, Button } from '@mui/material';
import React, { useEffect, useState} from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import { API } from '../../api/api_setup';
import { useSnackbar } from '../../hooks/useSnackbar';

const VerifyEmail = () => {
    const { token } = useParams()
    const { showSnackbar } = useSnackbar();
    const [disable, setDisable] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        API.post('/user/verify', {
            token
        })
        .then(resp => {
            showSnackbar("Email verified Successfully")
            setDisable(false)
        })
        .catch(err => {
            showSnackbar("This is not a valid verification request")
            setDisable(false)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box sx={{pt: "3rem", display: 'flex', justifyContent: 'space-around'}}>
            <Button 
            size='large' 
            disabled={disable}
            variant='contained'
            onClick={() => {
                navigate("/")
            }}
            >Back to Login</Button>
        </Box>
    );
}

export default VerifyEmail;
