import { Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { API } from '../../api/api_setup';
import DataDisplay from '../../components/DataDisplay';
import dayjs from 'dayjs';
import { countFormatterWithCommas, indexFormatter } from '../../utils/helpers';
import HomeIcon from '@mui/icons-material/Home';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useAuth } from '../../hooks/useAuth';
import { setDatasourcesOverview, 
  setSortModelStructured, 
  setSortModelUnstructured,
setStructuredData,
setUnstructuredData } from './datasourceSlice';

const getColumnsUnstructured = () => {
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      valueFormatter: indexFormatter
    },
    {
      field: 'datasourceName',
      headerName: 'Name',
      width: 150
    },
    {
      field: 'datasourceType',
      headerName: 'Type',
      width: 100
    }
  ]

  for (let i = 0; i < 14; i++) {
    let currentdate = dayjs(new Date())
    columns.push({
      field: `pendingCountDay${i}`,
      headerName: currentdate.subtract(i, 'days').format('MM/DD'),
      width: 150,
      valueFormatter: countFormatterWithCommas,
      renderCell: (cellValue) => {
        const currentCount = Number(cellValue.value)
        const prevCount = Number(cellValue.row[`pendingCountDay${i + 1}`])

        let color = "rgb(0, 179, 134)"
        let diff = currentCount - prevCount
        const copyDiff = diff
        if (diff >= 0) {
          color = "rgb(235, 91, 60)"
          diff = "+" + countFormatterWithCommas(diff)
        } else {
          diff = countFormatterWithCommas(diff)
        }

        let percentageChange = Math.round(Math.abs((copyDiff / prevCount) * 10000))
        percentageChange = percentageChange / 100
        if (!isFinite(percentageChange)) {
          percentageChange = "-%"
        } else {
          percentageChange = percentageChange + "%"
        }

        if (isNaN(currentCount) || isNaN(prevCount) || (prevCount === 0 && copyDiff === 0)) {
          return (<Typography variant='body2' textAlign='center' marginTop={2}>
            {countFormatterWithCommas(cellValue.value)}
          </Typography>)
        }

        return (
          <>
            <Typography variant='body2' textAlign='center' marginTop={'2px'}>
              {countFormatterWithCommas(cellValue.value)}
            </Typography>
            <Typography color={color} sx={{ marginTop: '1px', fontSize: '12px' }} textAlign='center'>
              {diff}({percentageChange})
            </Typography>
          </>
        )

      }
    })
  }

  return columns
}

const getColumnsStructured = () => {
  return [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      valueFormatter: indexFormatter
    },
    {
      field: 'datasourceName',
      headerName: 'Name',
      width: 200
    },
    {
      field: 'datasourceType',
      headerName: 'Type',
      width: 100
    },
    {
      field: 'lastScanResult',
      headerName: 'Last scan result',
      width: 150
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 200
    },
    {
      field: 'lastSuccessfulScan',
      headerName: 'Last successful scan',
      width: 200
    },
    {
      field: 'nextScheduledScan',
      headerName: 'Next scheduled scan',
      width: 200
    },
    {
      field: 'withoutConnectivity',
      headerName: 'Without connectivity',
      width: 200
    },
    {
      field: 'withoutPermissions',
      headerName: 'Without permissions',
      width: 200
    }
  ]
}

const DsrcOverview = () => {
  
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth()

  const { clusterName } = useParams()
  // const overviewData = useSelector(state => state.datasources.overview)
  const sortModelUnstructured = useSelector(state => state.datasources.sortModelUnstructured)
  const sortModelStructured = useSelector(state => state.datasources.sortModelStructured)
  const structuredData = useSelector(state => state.datasources.structuredData)
  const unstructuredData = useSelector(state => state.datasources.unstructuredData)

  const dispatch = useDispatch()
  const handleSortModelChange = (model, details) => {
    dispatch(setSortModelUnstructured(model))
  }

  const handleSortModelChangeStructured = (model, details) => {
    dispatch(setSortModelStructured(model))
  }

  useEffect(() => {
    API.get(`/datasource/overview/${clusterName}`, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    })
      .then(response => {
        dispatch(setDatasourcesOverview(response.data.data))
        dispatch(setUnstructuredData(response.data.data["unstructuredPendingCountTrend"]))
        dispatch(setStructuredData(response.data.data["structuredScanStatus"]))
        setIsLoading(false)
      })
      .catch(err => {
        showSnackbar("Something went wrong !!")
      })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusterName])

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12}>
          <Navbar leftWidgetList={[
            <IconButton
              size="large"
              color="inherit"
              aria-label="home"
              onClick={() => { navigate("/") }}
              sx={{ marginLeft: '-10px' }}
              key={0}
            >
              <HomeIcon sx={{ fontSize: 40 }} />
            </IconButton>,
            <Typography variant='h4' sx={{ marginLeft: '10px' }} key={1}>{clusterName}</Typography>
          ]} />
        </Grid>
        <Grid item lg={12} md={12} sx={{ padding: '15px' }}>
          <Typography variant='h5' color={'#313638'}>{"Pending count trend"}</Typography>
        </Grid>
        <Grid item lg={12} md={12} sx={{ maxHeight: '70vh', minHeight: '20vh' }}>
          <DataDisplay
            rows={unstructuredData.map((item, index) => ({ ...item, id: index }))}
            columns={getColumnsUnstructured()}
            loading={isLoading}
            sortModel={sortModelUnstructured}
            onSortModelChange={handleSortModelChange}
          />
        </Grid>
        <Grid item lg={12} md={12} sx={{ padding: '15px' }}>
          <Typography variant='h5' color={'#313638'}>{"Last scan status"}</Typography>
        </Grid>
        <Grid item lg={12} md={12} sx={{ maxHeight: '70vh', minHeight: '20vh' }}>
          <DataDisplay
            rows={structuredData.map((item, index) => ({ ...item, id: index }))}
            columns={getColumnsStructured()}
            loading={isLoading}
            sortModel={sortModelStructured}
            onSortModelChange={handleSortModelChangeStructured}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DsrcOverview;
