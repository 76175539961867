import {
    createBrowserRouter,
} from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard";
import NotFound from "./pages/notfound/NotFound";
import Clusters from "./pages/clusters/clusters";
import Home from "./pages/home/home";
import VerifyEmail from "./pages/verify_email/verify_email";
import { ProtectedRoute } from "./components/ProtectedRoute";
import {PublicRoute} from "./components/PublicRoute"
import Signup from "./pages/signup/signup";
import ForgotPassword from "./pages/forgot_password/forgot_password";
import ResetPassword from "./pages/reset_password/reset_password";
import DsrcOverview from "./pages/datasources/datasource";

const router = createBrowserRouter([
    {
        path: "/",
        element: <PublicRoute><Home/></PublicRoute>,
    },
    {
        path: "/signup",
        element: <PublicRoute><Signup/></PublicRoute>,
    },
    {
        path: "/forgot-password",
        element: <PublicRoute><ForgotPassword/></PublicRoute>
    },
    {
        path: "/reset_password/:token",
        element: <PublicRoute><ResetPassword/></PublicRoute>
    },
    {
        path: "/dashboard",
        element: <ProtectedRoute><Dashboard/></ProtectedRoute>,
    },
    {
        path: "/dashboard/:clusterName/datasources/overview",
        element: <ProtectedRoute><DsrcOverview/></ProtectedRoute>,
    },
    {
        path: "/clusters",
        element: <ProtectedRoute><Clusters/></ProtectedRoute>
    },
    {
        path: "/verify/:token",
        element: <PublicRoute><VerifyEmail/></PublicRoute>
    },
    {
        path: "*",
        element: <NotFound />
    }
]);

export default router