import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/Navbar';
import { API } from '../../api/api_setup';
import DataDisplay from '../../components/DataDisplay';
import { countFormatterWithCommas } from '../../utils/helpers';
import NumberDisplayCard from '../../components/NumberDisplayCard';
import { setClusters, setSortModel } from './dashboardSlice';
import {HomePageColumns} from '../../utils/constants'
import { useAuth } from '../../hooks/useAuth';
import { useSnackbar } from '../../hooks/useSnackbar';


const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useAuth()
  const sortModel = useSelector((state) => state.dashboard.sortModel)
  const clusters = useSelector((state) => state.dashboard.clusters)
  const dispatch = useDispatch()
  const  {showSnackbar} = useSnackbar()


  const handleSortModelChange = (model, details) => {
    dispatch(setSortModel(model))
  }
  const get_total_scanned_count = () => {
    let total = 0;
    for (const item of clusters) {
      total += item.overallScanned
    }
    return countFormatterWithCommas(total)
  }
  const handleCellClick = (params, event, details) => {
    
  }



  useEffect(() => {
    if (clusters && clusters.length > 0) {
      setIsLoading(false)
      return
    }

    API.get('cluster/overview', {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    })
      .then((response) => {
        dispatch(setClusters(response.data.data))
        setIsLoading(false)
      })
      .catch(err => {
        showSnackbar("Something went wrong !!")
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12}>
          <Navbar />
        </Grid>
        <Grid item lg={12} md={12} sx={{ display: 'flex', alignItems: 'baseline' }}>
          <NumberDisplayCard color={'blue'} variant='h3' infoText={'Total Scanned Count across all Clusters'} count={get_total_scanned_count()}/>
        </Grid>
        <Grid item lg={12} md={12} sx={{maxHeight: '70vh', minHeight: '20vh'}}>
          <DataDisplay 
          rows={clusters} 
          columns={HomePageColumns} 
          loading={isLoading} 
          handleCellClick={handleCellClick}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          />
        </Grid>
      </Grid>
    </>
  );
};


export default Dashboard;