import { createSlice } from '@reduxjs/toolkit'

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    clusters: [],
    sortModel: []
  },
  reducers: {
    setClusters: (state, action) => {
        state.clusters = action.payload
    },
    setRefreshData: (state, action) => {
      state.refreshData = action.payload
    },
    setSortModel: (state, action) => {
      state.sortModel = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setClusters, setRefreshData, setSortModel } = dashboardSlice.actions

export default dashboardSlice.reducer