import { Button, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import DataDisplay from '../../components/DataDisplay';
import { API } from '../../api/api_setup';
import ClusterSwitch from '../../components/ClusterSwitch';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useAuth } from '../../hooks/useAuth';


const Clusters = () => {
    const [clusters, setClusters] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [disableBtn, setDisableBtn] = useState(true)
    const { showSnackbar } = useSnackbar();
    const { user } = useAuth()
    const navigate = useNavigate()
    const handleClusterStateChange = ({
        clusterName,
        isInternal
    }) => {
        setDisableBtn(false)
        const modifiedArray = [...clusters]
        for(let cluster of modifiedArray) {
            if (cluster["clusterName"] === clusterName) {
                cluster["isInternal"] = isInternal
            }
        }
        setClusters(modifiedArray)
    }
    const handleSaveChanges = () => {

        API.patch('/cluster/patch', clusters , {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then((result) => {
            showSnackbar('Updated !!')
            setDisableBtn(true)
        })
        .catch(err => {
            showSnackbar('Something went wrong !!')
        })
    }

    useEffect(() => {
        API.get('/cluster/info', {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((result) => {
                setClusters(result.data.data)
                setIsLoading(false)
            })
        .catch(err => {
            showSnackbar("Something went wrong !!")
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const clusterColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'clusterName',
            headerName: 'Cluster name',
            width: 300,
        },
        {
            field: 'isInternal',
            headerName: 'Internal Cluster',
            width: 150,
            renderCell: (cellValue) => {
                return <ClusterSwitch cellValue={cellValue} handleClusterStateChange={handleClusterStateChange}/>
            }
        },
    ]

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={12} md={12}>
                    <Navbar leftWidgetList={[
                        <IconButton
                            size="large"
                            color="inherit"
                            aria-label="home"
                            onClick={() => { navigate("/") }}
                            sx={{ marginLeft: '-10px' }}
                            key={0}
                        >
                            <HomeIcon sx={{ fontSize: 40 }} />
                        </IconButton>,
                        <Typography variant='h4' sx={{ marginLeft: '10px' }} key={1}>{"Clusters"}</Typography>
                    ]} 
                    rightWidgetList={[
                        <Button 
                        variant='contained' 
                        color='secondary'
                        onClick={handleSaveChanges}
                        key={0}
                        disabled={disableBtn}
                        >Apply Changes</Button>
                    ]}
                    />
                </Grid>
                <Grid item lg={12} md={12}></Grid>
                <Grid item lg={12} md={12} sx={{maxHeight: '90vh', minHeight: '20vh'}}>
                    <DataDisplay
                        rows={clusters.map((item, index) => ({ ...item, id: index }))}
                        columns={clusterColumns}
                        loading={isLoading}
                        sx={{margin: '10px'}}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default Clusters;
