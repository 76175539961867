const countFormatterWithCommas = (count) => {
    if (isNaN(Number(count)))
      return "--"
    count = Math.round(count)
    return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

const indexFormatter = (index) => {
  return (index + 1)
}

const getPercentageSkippedOverTotal = (value, row) => {
    const numer = row.overallSkipped
    const denom = row.overallScanned
    if (denom === 0)
      return "--"
    const percent = ((numer * 100)/denom).toFixed(2)
    if (isNaN(percent))
      return "--"
    return `${percent}%`
}

const compareTo = (v1, v2) => {
  if (v1 < v2)
    return -1
  else if (v1 > v2)
    return 1
  else
    return 0
}

const commaValueComparator = (v1, v2) => {
    const regex = /[, %]/g
    v1 = String(v1)
    v2 = String(v2)
    v1 = Number(v1.replaceAll(regex, ""))
    v2 = Number(v2.replaceAll(regex, ""))
    if (isNaN(v1) && isNaN(v2))
        return 0
    if (isNaN(v2))
        return -1
    if (isNaN(v1))
        return 1
    return compareTo(v1,v2)
}

const multipurposeSortComparator = (sortDirection) => {
  const modifier = sortDirection === 'desc' ? -1 : 1;
  return (value1, value2, cellParams1, cellParams2) => {
    const regex = /[, %]/g
    value1 = Number(String(value1).replaceAll(regex, ""))
    value2 = Number(String(value2).replaceAll(regex, ""))
    if (isNaN(value1)) {
      return 1;
    }
    if (isNaN(value2)) {
      return -1;
    }
    return (
      modifier *
      compareTo(value1, value2)
    );
  };
}

export {
    countFormatterWithCommas,
    getPercentageSkippedOverTotal,
    commaValueComparator,
    multipurposeSortComparator,
    indexFormatter
}