import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import DrawerMenu from './DrawerMenu';


const Navbar = ({ rightWidgetList = [
    <DrawerMenu/>
], leftWidgetList = [
    <Typography variant="h6" noWrap component="div" key={0}>
        <Link to={`/`} style={{ color: 'white', textDecoration: 'None' }}>
            {"Lightbeam Watchtower"}
        </Link>
    </Typography>
] }) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: '0px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {
                            leftWidgetList.map((component, index) => {
                                let Component = component.type
                                return <Component key={index} {...component.props}/>
                            })
                        }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection:'row-reverse' }}>
                        {
                            rightWidgetList.map((component, index) => {
                                let Component = component.type
                                return <Component key={index} {...component.props}/>
                            })
                        }
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Navbar;
