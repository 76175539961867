import { SvgIcon } from "@mui/material";

function MenuIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z" />
        </SvgIcon>
    );
}

export default MenuIcon