// SnackbarContext.js
import React, { createContext, useState, useContext } from 'react';
import CustomSnackbar from '../components/CustomSnackbar';

const SnackbarContext = createContext();

export function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState({ message: '', open: false });
  
  const showSnackbar = (message) => {
    setSnackbar({ message, open: true });
    setTimeout(() => setSnackbar({ message: '', open: false }), 3000); // Auto-hide after 3 seconds
  };

  const hideSnackbar = () => {
    setSnackbar({ message: '', open: false });
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {snackbar.open && (
        <CustomSnackbar openSnackbar={snackbar.open} handleCloseSnackbar={hideSnackbar} message={snackbar.message}/>
      )}
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  return useContext(SnackbarContext);
}
