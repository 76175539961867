import { createSlice } from '@reduxjs/toolkit'

export const datasourcesSlice = createSlice({
  name: 'datasources',
  initialState: {
    sortModelUnstructured: [],
    structuredData: [],
    unstructuredData: [],
    sortModelStructured:[],
    overview: {
        "unstructuredPendingCountTrend":[],
        "structuredScanStatus": []
    }
  },
  reducers: {
    setSortModelUnstructured: (state, action) => {
        state.setSortModelUnstructured = action.payload
    },
    setSortModelStructured: (state, action) => {
        state.sortModelStructured = action.payload
    },
    setDatasourcesOverview: (state, action) => {
        state.overview = action.payload
    },
    setStructuredData: (state, action) => {
      state.structuredData = action.payload
    },
    setUnstructuredData: (state, action) => {
      state.unstructuredData = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setSortModelStructured, 
  setSortModelUnstructured, 
  setDatasourcesOverview,
  setStructuredData,
  setUnstructuredData
} = datasourcesSlice.actions

export default datasourcesSlice.reducer