import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { API } from '../../api/api_setup';
import { useSnackbar } from '../../hooks/useSnackbar';

const ForgotPassword = () => {
    const [email, setEmail] = useState("")
    const { showSnackbar } = useSnackbar()
    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }
    const handlePasswordReset = () => {
        const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}/
        if (!emailRegex.test(email)) {
            showSnackbar("Please enter a valid email")
            return;
        }
        API.post("/user/request_password_reset", {
            email
        })
            .then(resp => {
                showSnackbar("Password reset link sent to email")
            })
            .catch(err => {
                if (err.data && err.data.message)
                    showSnackbar(err.data.message)
                else if (err.response && err.response.data && err.response.data.message)
                    showSnackbar(err.response.data.message)
                else
                    showSnackbar("Something went wrong !!")
            })
    }

    return (
        <Grid container spacing={2} sx={{ flexGrow: 1, alignItems: 'center' }}>
            <Grid item lg={12} container alignItems="center" justifyContent="space-around">
                <Box component="form" sx={{ width: '100%', maxWidth: 400, marginTop: '50px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" gutterBottom>
                            Request password reset email
                        </Typography>
                    </Box>
                    <Divider sx={{ my: 4 }}>
                    </Divider>
                    <TextField
                        fullWidth
                        type='email'
                        label="Email address"
                        variant="outlined"
                        margin="normal"
                        placeholder="name@lightbeam.ai"
                        value={email}
                        onChange={onEmailChange}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth sx={{ mb: 2, mt: 4 }}
                        onClick={handlePasswordReset}
                    >
                        Send Password Reset Link
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}

export default ForgotPassword;
