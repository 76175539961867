import { Box, Button,  Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSnackbar } from '../../hooks/useSnackbar';
import { API } from '../../api/api_setup';

const Signup = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")

    const { showSnackbar } = useSnackbar();
    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value)
    }
    const onPasswordChange2 = (e) => {
        setPassword2(e.target.value)
    }

    const handleSignup = () => {
        const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}/
        if (!emailRegex.test(email)) {
          showSnackbar("Please enter a valid email")
          return;
        }
        if (!password || password.length < 8) {
          showSnackbar("Please enter a stronger password")
          return;
        }

        if(password !== password2) {
            showSnackbar("Entered passwords dont match")
            return;
        }

        API.post("/user/signup", {
            email,
            password
        })
        .then((response) => {
            showSnackbar(response.data.data)
        })
        .catch((err) => {
            if (err.data && err.data.message)
            showSnackbar(err.data.message)
            else if (err.response && err.response.data && err.response.data.message)
            showSnackbar(err.response.data.message)
            else
            showSnackbar("Something went wrong !!")
        })

    }


    return (
            <Grid container spacing={2} sx={{ flexGrow: 1, alignItems: 'center' }}>
                <Grid item lg={12} container alignItems="center" justifyContent="space-around">
                    <Box component="form" sx={{ width: '100%', maxWidth: 400, marginTop: '50px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom>
                                Sign in
                            </Typography>
                        </Box>
                        <Divider sx={{ my: 4 }}>
                        </Divider>
                        <TextField
                            fullWidth
                            type='email'
                            label="Email address"
                            variant="outlined"
                            margin="normal"
                            placeholder="name@lightbeam.ai"
                            value={email}
                            onChange={onEmailChange}
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            type="password"
                            variant="outlined"
                            margin="normal"
                            placeholder="password"
                            value={password}
                            onChange={onPasswordChange}
                        />
                        <TextField
                            fullWidth
                            label="Enter password again"
                            type="password"
                            variant="outlined"
                            margin="normal"
                            placeholder="password"
                            value={password2}
                            onChange={onPasswordChange2}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth sx={{ mb: 2 , mt: 4}}
                            onClick={handleSignup}
                        >
                            Signup
                        </Button>
                    </Box>
                </Grid>
            </Grid>
    );
}

export default Signup;
